import React from 'react';

const PageNotFound = () => {
    return(
        <div>
          <h1>Hello PageNotFound</h1> 
        </div>
    )
}

export default PageNotFound;